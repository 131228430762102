import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

import { rhythm } from '../utils/typography'

function Links() {
  return (
          <div
            style={{
              marginBottom: rhythm(1),
            }}
          >
            <h2>
              Things to Check Out
            </h2>
            <a href="http://rectangulo.us" target="_blank">Rectangulo.us</a>
            <br />
            <a href="http://codemusings.org/puck" target="_blank">Puck</a>
          </div>
        )
      }

export default Links
